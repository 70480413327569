<template>
  <div id="inner-room-settings">
    <div id="inner-content">
      <div id="button-back-container">
        <a href="#" class="link-common" @click="goBack">
          <img src="@/assets/images/icons/icon-back-arrow.svg" alt="back-arrow">
          {{$t('converted.back')}}
        </a>
      </div>
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ViewAllTab',
  components: {
  },
  data() {
    return {}
  },
  computed: {},
  created() {
  },
  beforeDestroy() {},
  methods: {
    goBack() {
      this.$router.go(-1);
    }
  },
}
</script>

<style scoped>
#inner-room-settings {
  padding: 33px 15px 50px;
}
#button-back-container {
  margin-bottom: 40px;
  height: 22px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  transition: .2s;
  position: relative;
}
#button-back-container a img {
  vertical-align: middle;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transition: .2s;
  position: relative;
  top: -2px;
  left: -2px;
}
#button-back-container a:hover img {
  left: -10px;
}
</style>
